
import { Component, Vue } from 'vue-property-decorator'
import { BuildFacilitiesInfo } from '@/types/buildFacilities.d'
import MaintenanceDialog from '@/components/maintenanceDialog/Index.vue'
import { MaintenanceForm } from '@/types/common'

@Component({
  name: 'BuildFacilities',
  components: { MaintenanceDialog }
})
export default class extends Vue {
  private info = {
    projectName: '',
    buildName: ''
  }

  private buildId = ''
  private loading = false
  private tableData: BuildFacilitiesInfo[] = []
  private showDialog = false
  private isBatch = false
  private selects: BuildFacilitiesInfo[] = []
  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<BuildFacilitiesInfo> }>(this.$apis.buildFacilities.selectBuildFacilitiesByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onAdd () {
    this.$router.push({
      name: 'buildFacilitiesAdd'
    })
  }

  onUpdate (buildId: string) {
    this.$router.push({
      name: 'buildFacilitiesUpdate',
      params: { buildId }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.buildFacilities.deleteBuildFacilities, {
          buildId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onDetail (buildId: string) {
    this.$router.push({
      path: `/buildFacilitiesList/detail${buildId}/buildDetail`
    })
  }

  openRecord (buildId: string) {
    this.showDialog = true
    this.buildId = buildId
  }

  // 批量添加养护记录
  addMaintenanceBatch () {
    if (this.selects && this.selects.length > 0) {
      this.isBatch = true
      this.showDialog = true
    } else {
      this.$message.warning('未选中建筑设施，无法批量维护')
    }
  }

  // 勾选更改
  handleSelectionChange (val: Array<BuildFacilitiesInfo>) {
    this.selects = val
  }

  // 添加维护记录
  onAddRecord (formData: MaintenanceForm) {
    let url = this.$apis.record.insertMaintainRecord
    let info: { objectId?: string; objectIds?: Array<string> } = { objectId: this.buildId }
    if (this.isBatch) {
      url = this.$apis.record.insertAllMaintainRecord
      info = { objectIds: [] }
      this.selects.forEach(item => {
        (info.objectIds as Array<string>).push(item.buildId)
      })
    }
    this.$axios.post(url, {
      ...info,
      ...formData
    }).then(() => {
      this.$message.success('添加成功')
      this.getData()
    }).finally(() => {
      this.showDialog = false
      this.isBatch = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
