
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MaintenanceForm, FileInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { twoDecimal } from '@/utils/validate'

@Component({
  name: 'MaintenanceDialog',
  components: { UploadFile }
})
export default class MaintenanceDialog extends Vue {
  @Prop({ default: '添加养护档案' }) private title!: string
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: '养护' }) private tag!: boolean
  @Prop({ default: false }) private typeShow?: boolean
  @Prop({ default: true }) private requiredContent?: boolean
  @Prop({ default: false }) private ordinarySeedlings?: boolean // 是否显示养护状态、价值评估、胸襟、冠幅、树高（新增普通苗木单个档案以及查看普通苗木记录显示）
  @Prop({ default: false }) private tipsShow?: boolean // 批量新增文字提示是否显示

// 最大为999999999.99
private maximum = (rule: object, value: string, callback: Function) => {
  if (parseFloat(value) > 999999999.99) {
    return callback(new Error('最大999999999.99'))
  } else {
    return callback()
  }
}

  @Prop({
    default: () => {
      return {
        maintainDate: '',
        maintainContent: '',
        resourceList: [],
        configId: [],
        maintainStatus: '',
        chestDiameter: '',
        crownWidth: '',
        treeHeight: '',
        qualityLevel: '',
        valueAssessment: ''
      }
    }
  }) private formData?: MaintenanceForm

  private saveDisabled= false

  private rules = {
    maintainDate: [
      {
        required: true,
        message: `请选择${this.tag}日期`,
        trigger: 'blur'
      }
    ],
    maintainContent: [
      { required: this.requiredContent, message: `请填写${this.tag}内容`, trigger: 'blur' }
    ],
    configId: [
      { required: true, message: '请选择养护类型', trigger: 'blur' }
    ],
    valueAssessment: [
      { validator: twoDecimal },
      { validator: this.maximum }
    ],
    chestDiameter: [
      { validator: twoDecimal },
      { validator: this.maximum }
    ],
    crownWidth: [
      { validator: twoDecimal },
      { validator: this.maximum }
    ],
    treeHeight: [
      { validator: twoDecimal },
      { validator: this.maximum }
    ]
  }

  // 养护类型
  private maintainList = []
  private seedlingStatusList = []
  private seedlingLevelList = []

  created () {
    this.typeShow && this.selectMaintainList()
    this.ordinarySeedlings && this.getDicList()
  }

  // 查询养护类型
  selectMaintainList () {
    this.$axios.get(this.$apis.record.selectConfigByList).then((res) => {
      this.maintainList = res.list || []
    })
  }

  getDicList () {
    this.$axios.get(this.$apis.common.selectDicByList2, {
      dicType: 'maintainStatus,qualityLevel'
    }).then((res) => {
      this.seedlingStatusList = res.maintainStatus || []
      this.seedlingLevelList = res.qualityLevel || []
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    ;(this.formData as MaintenanceForm).resourceList.push(file)
  }

  imgRemove (index: number) {
    ;(this.formData as MaintenanceForm).resourceList.splice(index, 1)
  }

  handleClose () {
    this.$emit('close')
  }

  save () {
    ;(this.$refs.ruleForm as ElForm).validate((valid) => {
      if (valid) {
        this.$emit('save', this.formData)
      }
    })
  }

  cancel () {
    this.$emit('close')
  }

  // 按钮状态切换
  saveDisabledShow () {
    this.saveDisabled = false
  }

  destroyed () {
    this.saveDisabled = false
  }
}
